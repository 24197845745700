









































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab1Question1',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        answer1: null,
        language: 'en',
      },
      rules: [(v: string) => v?.length <= 1000 || 'Max 1000 characters'],
      questions: [
        {
          en: 'Why is it important to make co-spots last?',
          fr: 'Pourquoi est-il important d’appliquer l’échantillon dans la zone du point combiné en dernier ?',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
